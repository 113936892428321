<template>
    <div id="stakeholder-form">
        <div class="vx-row">
            <!-- error -->
            <div class="vx-col w-full">
                <vs-alert
                    v-if="errorMssg"
                    icon-pack="feather"
                    icon="icon-alert-triangle"
                    class="mb-5"
                    color="danger">
                    <span class="font-regular">{{ errorMssg }}</span>
                </vs-alert>
            </div>

            <!-- {{ stakeholder }} -->

            <!-- EN CASO DE QUE SEAN MÁS DE 4 -->
            <div v-if="hasMoreThanFourStakeholders && !isRepLegal" class="vx-col w-full mb-5 ml-2">
                <p class="mb-5">Ingresa los datos de la persona que tiene mayor número de acciones.</p>

                <div class="mb-5">
                    <ul class="centerx">
                        <li class="mt-2">
                            <vs-radio v-model="stakeholder.is_max_percentage" vs-value="1">Yo soy la persona con más acciones.</vs-radio>
                        </li>
                        <li class="mt-2">
                            <vs-radio v-model="stakeholder.is_max_percentage" vs-value="0">Es otra persona.</vs-radio>
                        </li>
                    </ul>
                </div>
                
                <p>Completa la información de la persona que tiene mayor número de acciones.</p>
            </div>

            <div v-if="!isRepLegal" :class="colClass">
                <vs-select
                    class="w-full"
                    v-model.lazy="stakeholder.person_type" 
                    label="Tipo de persona" 
                    v-validate="requiredRules"
                    :disabled="isRepLegal || stakeholderAlreadyExist"
                    v-on:change="$validator.reset()"
                    name="stakeholder.person_type"
                    :danger="hasError('stakeholder.person_type')" 
                    :danger-text="errorText('stakeholder.person_type')"
                    :success="isSuccess('stakeholder.person_type')"
                    :placeholder="selectPlaceholder">
                    <vs-select-item v-for="(item, index) in personTypeOptions"
                        :text="item.label" :key="index"
                        :value="item.value"></vs-select-item>
                </vs-select>
            </div>
            <div v-if="!isRepLegal" :class="colClass">
                <vs-input
                    type="number"
                    max="100"
                    min="1"
                    class="w-full"
                    label="Porcentaje de acciones"
                    name="share_percentage"
                    v-validate="requiredRules"
                    v-model.number="stakeholder.share_percentage"
                    :danger="hasError('share_percentage')"
                    :danger-text="errorText('share_percentage')"
                    :success="isSuccess('share_percentage')"
                    icon-pack="feather" />
            </div>
            <!-- FORM PARA REPLEGAL -->
            <template v-if="isRepLegal">
                <!-- <div :class="colClass">
                    <vs-select
                        class="w-full"
                        v-model.lazy="stakeholder.is_max_percentage" 
                        label="¿Es accionista?" 
                        v-validate="requiredRules"
                        v-on:change="controlStakeHoldersByRepLegal"
                        name="stakeholder.is_max_percentage"
                        :danger="hasError('stakeholder.is_max_percentage')" 
                        :danger-text="errorText('stakeholder.is_max_percentage')"
                        :success="isSuccess('stakeholder.is_max_percentage')"
                        :placeholder="selectPlaceholder">
                        <vs-select-item v-for="(item, index) in isStakeholderOptions"
                            :text="item.label" :key="index"
                            :value="item.value"></vs-select-item>
                    </vs-select>
                </div> -->
                <div v-if="stakeholder.is_max_percentage" :class="colClass">
                    <vs-input
                        type="number"
                        max="100"
                        min="1"
                        class="w-full"
                        label="Porcentaje de acciones"
                        name="share_percentage"
                        v-validate="requiredRules"
                        v-model.number="stakeholder.share_percentage"
                        :danger="hasError('share_percentage')"
                        :danger-text="errorText('share_percentage')"
                        :success="isSuccess('share_percentage')"
                        icon-pack="feather" 
                        :disabled="stakeholderAlreadyExist"/>
                </div>
                <div :class="colClass">
                    <vs-input 
                        class="w-full" 
                        label="Correo electrónico"
                        name="email" 
                        v-validate="emailRules"
                        v-model.lazy="stakeholder.email"
                        :danger="hasError('email')" 
                        :danger-text="errorText('email')"
                        :success="isSuccess('email')" 
                        icon-pack="feather"
                        :disabled="repLegalIsMainUser"
                        :placeholder="emailPlaceholder" />
                </div>
                <div :class="colClass">
                    <vs-input
                        @input="(val) => (profile.first_name = profile.first_name.toUpperCase())"
                        class="w-full"
                        label="Primer nombre"
                        name="firstName"
                        v-validate="requiredRules"
                        v-model.trim="profile.first_name"
                        :danger="hasError('firstName')"
                        :danger-text="errorText('firstName')"
                        :success="isSuccess('firstName')"
                        icon-pack="feather"
                        :disabled="repLegalIsMainUser"
                        :placeholder="requiredPlaceholder"
                    />
                </div>
                <div :class="colClass">
                    <vs-input
                        @input="(val) => (profile.second_name = profile.second_name.toUpperCase())"
                        class="w-full"
                        label="Segundo nombre"
                        name="second_name"
                        v-model.trim="profile.second_name"
                        :danger="hasError('second_name')"
                        :danger-text="errorText('second_name')"
                        :success="isSuccess('second_name')"
                        icon-pack="feather"
                        :disabled="repLegalIsMainUser"
                        :placeholder="optionalPlaceholder"
                    />
                </div>
                <div :class="colClass">
                    <vs-input
                        @input="(val) => (profile.last_name_1 = profile.last_name_1.toUpperCase())"
                        class="w-full"
                        label="Primer apellido"
                        name="last_name_1"
                        v-validate="requiredRules"
                        v-model.trim="profile.last_name_1"
                        :danger="hasError('last_name_1')"
                        :danger-text="errorText('last_name_1')"
                        :success="isSuccess('last_name_1')"
                        icon-pack="feather"
                        :disabled="repLegalIsMainUser"
                        :placeholder="requiredPlaceholder"
                    />
                </div>
                <div :class="colClass">
                    <vs-input
                        @input="(val) => (profile.last_name_2 = profile.last_name_2.toUpperCase())"
                        class="w-full"
                        label="Segundo apellido"
                        name="last_name_2"
                        v-validate="requiredRules"
                        v-model.trim="profile.last_name_2"
                        :danger="hasError('last_name_2')"
                        :danger-text="errorText('last_name_2')"
                        :success="isSuccess('last_name_2')"
                        icon-pack="feather"
                        :disabled="repLegalIsMainUser"
                        :placeholder="requiredPlaceholder"
                    />
                </div>
                <div :class="colClass">
                    <label class="vs-input--label"
                        >Fecha de nacimiento</label
                    >
                    <vs-input
                        class="w-full"
                        type="date"
                        name="birthDate" 
                        v-model.lazy="profile.birth_date"
                        v-validate="requiredRules"
                        :danger="hasError('birthDate')"
                        :danger-text="errorText('birthDate')"
                        :success="isSuccess('birthDate')"
                        format="dd/MM/yyyy"
                        :disabled="repLegalIsMainUser"
                        :max="disabledDates.from"/>

                    <!-- <datepicker
                        class="w-full"
                        v-model.lazy="profile.birth_date"
                        name="birthDate"
                        :language="lang_es"
                        v-validate="requiredRules"
                        :danger="hasError('birthDate')"
                        :danger-text="errorText('birthDate')"
                        :success="isSuccess('birthDate')"
                        format="dd/MM/yyyy"
                        initial-view="year"
                        :disabled-dates="disabledDates"
                        :placeholder="requiredPlaceholder"
                        :disabled="repLegalIsMainUser"
                        :use-utc="true"
                        :typeable="false"
                    /> -->
                </div>
                <!-- <div :class="colClass">
                    <vs-select
                        class="w-full"
                        v-model="stakeholder.role_type" 
                        label="Rol de la empresa" 
                        v-validate="requiredRules"
                        name="stakeholder.role_type"
                        :disabled="repLegalIsMainUser"
                        :danger="hasError('stakeholder.role_type')" 
                        :danger-text="errorText('stakeholder.role_type')"
                        :success="isSuccess('stakeholder.role_type')">
                        <vs-select-item v-for="(item, index) in roleTypeOptions"
                            :text="item.label" :key="index"
                            :value="item.value"></vs-select-item>
                    </vs-select>
                </div> -->
                <div :class="colClass">
                    <label class="vs-input--label">Celular a 10 dígitos</label>
                    <div class="flex">
                        <vue-country-code name="dialCode" class="vs-custom"
                            data-vv-name="dialCode" :enableSearchField="true"
                            searchPlaceholderText="Buscar código de país"
                            :enabledCountryCode="true" @onSelect="onSelect"
                            :preferredCountries="['MX']" :danger="hasError('dialCode')"
                            :danger-text="errorText('dialCode')"
                            :success="isSuccess('dialCode')"
                            :disabled="phoneIsVerified">
                        </vue-country-code>
                        <vs-input class="w-full" label="" name="phone" type="tel"
                            v-validate="'required|numeric'"
                            v-model.lazy="profile.phone.phone"
                            :danger="hasError('phone')"
                            :danger-text="errorText('phone')"
                            :success="isSuccess('phone')" icon-pack="feather"
                            placeholder="(Requerido) Ingresa 10 dígitos" 
                            :disabled="phoneIsVerified"/>
                    </div>
                </div>
                <div :class="colClass">
                    <vs-input
                        @input="(val) => (profile.rfc = profile.rfc.toUpperCase())"
                        class="w-full"
                        label="RFC"
                        name="rfc"
                        v-validate="requiredRules"
                        v-model.lazy="profile.rfc"
                        :danger="hasError('rfc')"
                        :danger-text="errorText('rfc')"
                        :success="isSuccess('rfc')"
                        icon-pack="feather"
                        :disabled="repLegalIsMainUser"
                        :placeholder="requiredPlaceholder" />
                </div>
                <div :class="colClass">
                    <vs-input
                        @input="(val) => (profile.curp = profile.curp.toUpperCase())"
                        class="w-full"
                        label="CURP"
                        name="curp"
                        v-validate="requiredRules"
                        v-model.lazy="profile.curp"
                        :danger="hasError('curp')"
                        :danger-text="errorText('curp')"
                        :success="isSuccess('curp')"
                        icon-pack="feather"
                        :disabled="repLegalIsMainUser"
                        :placeholder="requiredPlaceholder" />
                </div>
            </template>

            <!-- FORM PARA ACCIONISTAS -->
            <template v-else>
                <!-- SÍ ES PERSONA MORAL -->
                <template v-if="isPM">
                    <div :class="colClass">
                        <vs-input
                            @input="(val) => (profile.name = profile.name.toUpperCase())"
                            class="w-full"
                            label="Nombre de la empresa"
                            name="name"
                            v-validate="requiredRules"
                            v-model="profile.name"
                            :danger="hasError('name')"
                            :danger-text="errorText('name')"
                            :success="isSuccess('name')"
                            icon-pack="feather"
                            :placeholder="requiredPlaceholder"
                        />
                    </div>
                    <div :class="colClass">
                        <label class="vs-input--label"
                            >Fecha de creación de la empresa</label
                        >
                        <vs-input
                            class="w-full"
                            type="date" 
                            name="birthDate"
                            v-model.lazy="profile.constitution_date"
                            v-validate="requiredRules"
                            :danger="hasError('birthDate')"
                            :danger-text="errorText('birthDate')"
                            :success="isSuccess('birthDate')"
                            format="dd/MM/yyyy" />
                        <!-- <datepicker
                            class="w-full"
                            v-model.lazy="profile.constitution_date"
                            name="birthDate"
                            :language="lang_es"
                            v-validate="requiredRules"
                            :danger="hasError('birthDate')"
                            :danger-text="errorText('birthDate')"
                            :success="isSuccess('birthDate')"
                            format="dd/MM/yyyy"
                            initial-view="year"
                            :disabled-dates="disabledDates"
                            :placeholder="requiredPlaceholder"
                            :use-utc="true"
                            :typeable="false"
                            :disabled="stakeholderAlreadyExist"
                        /> -->
                    </div>
                    <!-- <div :class="colClass">
                        <vs-select
                            class="w-full"
                            v-model="stakeholder.role_type" 
                            label="Rol de la empresa" 
                            v-validate="requiredRules"
                            name="stakeholder.role_type"
                            :danger="hasError('stakeholder.role_type')" 
                            :danger-text="errorText('stakeholder.role_type')"
                            :success="isSuccess('stakeholder.role_type')"
                            :placeholder="selectPlaceholder"
                            :disabled="stakeholderAlreadyExist">
                            <vs-select-item v-for="(item, index) in roleTypeOptions"
                                :text="item.label" :key="index"
                                :value="item.value"></vs-select-item>
                        </vs-select>
                    </div> -->
                    <div :class="colClass">
                        <vs-input
                            @input="(val) => (profile.rfc = profile.rfc.toUpperCase())"
                            class="w-full"
                            label="RFC"
                            name="rfc"
                            v-validate="requiredRules"
                            v-model.lazy="profile.rfc"
                            :danger="hasError('rfc')"
                            :danger-text="errorText('rfc')"
                            :success="isSuccess('rfc')"
                            icon-pack="feather"
                            :placeholder="requiredPlaceholder"/>
                    </div>
                    <div :class="colClass">
                        <vs-input 
                            class="w-full" 
                            label="Correo electrónico del representante legal"
                            name="email" 
                            v-validate="emailRules"
                            v-model.lazy="stakeholder.email"
                            :danger="hasError('email')" 
                            :danger-text="errorText('email')"
                            :success="isSuccess('email')" 
                            icon-pack="feather"
                            :placeholder="emailPlaceholder" />
                    </div>
                    <div :class="colClass">
                        <label class="vs-input--label">Celular a 10 dígitos del representante legal</label>
                        <div class="flex">
                            <vue-country-code name="dialCode" class="vs-custom"
                                data-vv-name="dialCode" :enableSearchField="true"
                                searchPlaceholderText="Buscar código de país"
                                :enabledCountryCode="true" @onSelect="stakeholderOnSelect"
                                :preferredCountries="['MX']" :danger="hasError('dialCode')"
                                :danger-text="errorText('dialCode')"
                                :success="isSuccess('dialCode')">
                            </vue-country-code>
                            <vs-input class="w-full" label="" name="phone" type="tel"
                                v-validate="'required|numeric'"
                                v-model.lazy="profile.phone.phone"
                                :danger="hasError('phone')"
                                :danger-text="errorText('phone')"
                                :success="isSuccess('phone')" icon-pack="feather"
                                placeholder="(Requerido) Ingresa 10 dígitos"/>
                        </div>
                    </div>
                </template>
                <!-- SÍ ES PERSONA FISICA -->
                <template v-else-if="isPF">
                    <div :class="colClass">
                        <vs-input
                            @input="(val) => (profile.first_name = profile.first_name.toUpperCase())"
                            class="w-full"
                            label="Primer nombre"
                            name="firstName"
                            v-validate="requiredRules"
                            v-model.trim="profile.first_name"
                            :danger="hasError('firstName')"
                            :danger-text="errorText('firstName')"
                            :success="isSuccess('firstName')"
                            icon-pack="feather"
                            :placeholder="requiredPlaceholder"
                        />
                    </div>
                    <div :class="colClass">
                        <vs-input
                            @input="(val) => (profile.second_name = profile.second_name.toUpperCase())"
                            class="w-full"
                            label="Segundo nombre"
                            name="second_name"
                            v-model.trim="profile.second_name"
                            :danger="hasError('second_name')"
                            :danger-text="errorText('second_name')"
                            :success="isSuccess('second_name')"
                            icon-pack="feather"
                            :placeholder="optionalPlaceholder"
                        />
                    </div>
                    <div :class="colClass">
                        <vs-input
                            @input="(val) => (profile.last_name_1 = profile.last_name_1.toUpperCase())"
                            class="w-full"
                            label="Primer apellido"
                            name="last_name_1"
                            v-validate="requiredRules"
                            v-model.trim="profile.last_name_1"
                            :danger="hasError('last_name_1')"
                            :danger-text="errorText('last_name_1')"
                            :success="isSuccess('last_name_1')"
                            icon-pack="feather"
                            :placeholder="requiredPlaceholder"
                        />
                    </div>
                    <div :class="colClass">
                        <vs-input
                            @input="(val) => (profile.last_name_2 = profile.last_name_2.toUpperCase())"
                            class="w-full"
                            label="Segundo apellido"
                            name="last_name_2"
                            v-model.trim="profile.last_name_2"
                            :danger="hasError('last_name_2')"
                            :danger-text="errorText('last_name_2')"
                            :success="isSuccess('last_name_2')"
                            icon-pack="feather"
                            :placeholder="requiredPlaceholder"
                        />
                    </div>
                    <div :class="colClass">
                        <label class="vs-input--label"
                            >Nacionalidad</label
                        >
                        <v-select
                            class="vs-custom"
                            name="citizenship"
                            :class="{
                            'has-error': hasError('citizenship'),
                            'is-success': isSuccess('citizenship'),
                            }"
                            v-model.lazy="profile.citizenship"
                            v-validate="requiredRules"
                            :options="collections.countries"
                            :reduce="(item) => item.value"
                            :clearable="false"
                            placeholder="Selecciona una opción"
                            :searchable="false"
                        >
                        </v-select>
                        <v-error v-if="hasError('citizenship')" :error="errorText('citizenship')" />
                    </div>
                    <div :class="colClass">
                        <label class="vs-input--label"
                            >Fecha de nacimiento</label
                        >
                        <vs-input
                            class="w-full"
                            type="date" 
                            name="birthDate"
                            v-model.lazy="profile.birth_date"
                            v-validate="requiredRules"
                            :danger="hasError('birthDate')"
                            :danger-text="errorText('birthDate')"
                            :success="isSuccess('birthDate')"
                            format="dd/MM/yyyy" />
                        <!-- <datepicker
                            class="w-full"
                            v-model.lazy="profile.birth_date"
                            name="birthDate"
                            :language="lang_es"
                            v-validate="requiredRules"
                            :danger="hasError('birthDate')"
                            :danger-text="errorText('birthDate')"
                            :success="isSuccess('birthDate')"
                            format="dd/MM/yyyy"
                            initial-view="year"
                            :disabled-dates="disabledDates"
                            :placeholder="requiredPlaceholder"
                            :use-utc="true"
                            :typeable="false"
                            :disabled="stakeholderAlreadyExist" 
                        />-->
                    </div>
                    <div :class="colClass">
                        <vs-input 
                            class="w-full" 
                            label="Correo electrónico"
                            name="email" 
                            v-validate="emailRules"
                            v-model.lazy="stakeholder.email"
                            :danger="hasError('email')" 
                            :danger-text="errorText('email')"
                            :success="isSuccess('email')" 
                            icon-pack="feather"
                            :placeholder="emailPlaceholder" />
                    </div>
                    <div :class="colClass">
                        <label class="vs-input--label">Celular a 10 dígitos</label>
                        <div class="flex">
                            <vue-country-code name="dialCode" class="vs-custom"
                                data-vv-name="dialCode" :enableSearchField="true"
                                searchPlaceholderText="Buscar código de país"
                                :enabledCountryCode="true" @onSelect="stakeholderOnSelect"
                                :preferredCountries="['MX']" :danger="hasError('dialCode')"
                                :danger-text="errorText('dialCode')"
                                :success="isSuccess('dialCode')">
                            </vue-country-code>
                            <vs-input class="w-full" label="" name="phone" type="tel"
                                v-validate="'required|numeric'"
                                v-model.lazy="profile.phone.phone"
                                :danger="hasError('phone')"
                                :danger-text="errorText('phone')"
                                :success="isSuccess('phone')" icon-pack="feather"
                                placeholder="(Requerido) Ingresa 10 dígitos"/>
                        </div>
                    </div>
                    <!-- <div :class="colClass">
                        <vs-select
                            class="w-full"
                            v-model="stakeholder.role_type" 
                            label="Rol de la empresa" 
                            v-validate="requiredRules"
                            name="stakeholder.role_type"
                            :danger="hasError('stakeholder.role_type')" 
                            :danger-text="errorText('stakeholder.role_type')"
                            :success="isSuccess('stakeholder.role_type')"
                            :placeholder="selectPlaceholder"
                            :disabled="stakeholderAlreadyExist">
                            <vs-select-item v-for="(item, index) in roleTypeOptions"
                                :text="item.label" :key="index"
                                :value="item.value"></vs-select-item>
                        </vs-select>
                    </div> -->
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import dateHelper from "@mixins/dateHelper";
import { es } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import formatHelper from "@mixins/formatHelper";

const isStakeholderOptions = [
    { label: "Sí", value: true },
    { label: "No", value: false }
];
const personTypeOptions = [
    { label: "Persona Física", value: "1" },
    { label: "Persona Moral", value: "2" }
];

const roleTypeOptions = [
    { label: "Representante legal", value: "1" },
    { label: "Accionista", value: "2" },
    { label: "Representante legal y accionista", value: "3" }
];

export default {
    name: "StakeholderForm",
    mixins: [inputHelper, dateHelper, formatHelper],
    components: {
        Datepicker
    },
    props: {
        stakeholder: {
            type: Object,
            default: null
        },
        repLegalIsMainUser : {
            type: Boolean,
            default: false
        },
        hasMoreThanFourStakeholders: {
            type: Boolean,
            default: false
        },
        repLegalIsStakeholder: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        errorMssg: null,
        colClass: "vx-col sm:w-full md:w-1/2 mb-5",
        requiredPlaceholder: '(Requerido)',
        selectPlaceholder: 'Selecciona una opción',
        optionalPlaceholder: '(Opcional)',
        emailRules: 'required|email',
        emailPlaceholder: 'ejemplo@mail.com',
        requiredRules: 'required',
        personTypeOptions: personTypeOptions,
        roleTypeOptions: roleTypeOptions,
        isStakeholderOptions: isStakeholderOptions,
        lang_es: es,
        disabledDates: {
            from: new Date(),
        },
        isValid: false,
        collections: {
            countries: []
        },
    }),
    watch: {

    },
    computed: {
        isRepLegal() {
            return this.stakeholder && this.stakeholder.role === "legal_rep";
        },
        profile() {
            return this.stakeholder.profile;
        },
        phoneIsVerified() {
            return this.profile.phone && this.profile.phone.sms_verification_date != null;
        },
        isPM() {
            return this.stakeholder.person_type === "2";
        },
        isPF() {
            return this.stakeholder.person_type === "1";
        },
        stakeholderAlreadyExist() {
            return this.stakeholder.id != null;
        }
    },
    mounted() {
        this.getCollections();
        if(this.isRepLegal) {
            this.stakeholder.person_type = "1";
            this.stakeholder.role_type = "1";
        } else {
            this.stakeholder.role_type = "2";
        }

        let validDate = this.formatDatepicker(new Date(this.minDateForValidAge(18)));
        this.disabledDates.from = validDate;
    },
    methods: {
        async getCollections(){
            try {
                let collectionsObjects = ['countriesList'];
                let params = "with[]=" + collectionsObjects.join("&with[]=");
                let res = await this.publicApiGet(`/api/v1/forms/getPublicFormCollections?${params}`);
                let colls = res.data;
                colls.countriesList.forEach(opt => {
                    this.collections.countries.push({value: parseInt(opt.id), label: opt.name}); //conversion a sintaxis de vx-select
                });
            }
            catch (e) {
            }
        },
        async saveDataForm() {
            this.errorMssg = null;

            // Valida la fecha de nacimiento que no sea menor a 18 años
            if(!this.isPM) {
                if(this.isAfter(this.profile.birth_date, this.disabledDates.from)) {
                    this.missingFieldsNotif(null, "La fecha de nacimiento no puede ser mayor a " + this.disabledDates.from);
                    return;
                }
            }
             
            if(this.hasMoreThanFourStakeholders && !this.isRepLegal && this.stakeholder.is_max_percentage == null) {
                this.errorMssg = "Por favor, selecciona sí es el accionista mayoritario.";
                this.stakeholder.isValid = false;
                return false;
            }

            if (!(await this.validateForm())) {
                // console.log(this.$validator.fields);
                this.errorMssg = "Por favor, completa los campos requeridos.";
                this.stakeholder.isValid = false;
                return false;
            }

            if(this.isRepLegal || this.isPM) {
                let validRfc = this.isValidRfcFormat(this.profile.rfc);
                if(!validRfc){
                    this.errorMssg = "La estructura de la clave RFC es incorrecta.";
                    this.stakeholder.isValid = false;
                    return false;
                }
            }

            // if(this.isRepLegal) {
            //     let validCurp = this.isValidCurpFormat(this.profile.curp);
            //     if(!validCurp){
            //         this.errorMssg = "La estructura de la clave CURP es incorrecta.";
            //         this.stakeholder.isValid = false;
            //         return false;
            //     }
            // }

            this.stakeholder.isValid = true;
            return true;
        },
        async validateForm() {
            this.errorMssg = null;
            return this.$validator.validateAll();
        },
        hasError(val) {
            return this.errors.has(val);
        },
        errorText(val) {
            return this.errors.first(val);
        },
        isSuccess(val) {
            let ff = this.fields[val];
            return ff && ff.required && ff.valid;
        },
        onSelect({ dialCode }) {
            this.profile.phone.country_calling_code = `+${dialCode}`;
        },
        stakeholderOnSelect({ dialCode }) {
            this.profile.phone.country_calling_code = `+${dialCode}`;
        },
        async controlStakeHoldersByRepLegal() {
            await this.$emit("change", this.stakeholder.is_max_percentage);
            this.$validator.reset();
        }
    }
}
</script>